import Image from 'next/image'
import styles from '../../components/style/home/MarketPlaceTopProducts.module.css'
import { NavLink } from '../ui'
import { pushRoute } from '@/lib/helpers/common';
import { useGlobalContext } from '@/lib/context';
export function MarketPlaceTopProducts() {
    const baseUrl = `${process.env.SITE_URL}/online-store`;
    const { props: { isMobileView } } = useGlobalContext();
    const data = [
        {
            image: "sofas-chair.png",
            url: "/furniture/sofas",
            name: "Sofas"
        },
        {
            image: "coffeTable.png",
            url: "/furniture/coffee-tables",
            name: "Coffee Tables"
        },
        {
            image: "beds.png",
            url: "/furniture/beds",
            name: "Beds"
        },
        {
            image: "tvUnits.png",
            url: "/furniture/tv-units",
            name: "TV Units"
        },
        {
            image: "kidsCabinets.png",
            url: "/kids-and-teens/kids-storage-cabinets",
            name: "Kids Storage"
        },
        {
            image: "loungeChair.png",
            url: "/furniture/chairs",
            name: "Lounge Chairs"
        },
        {
            image: "diningTables.png",
            url: "/furniture/dining-table-sets",
            name: "Dining Tables"
        },
        {
            image: "kidsBeds.png",
            url: "/kids-and-teens/kids-beds",
            name: "Kids Beds"
        },
        {
            image: "wallSconces.png",
            url: "/lighting/wall-sconces",
            name: "Wall Sconces"
        }
    ]
    return (<section className={styles.exploreProductsMain}>
        <div className="container">
            <h2 className="font26 text222 fbold lineHeight26">Discover Your Dream Home: Dive into Our Decor & Furniture Collection!</h2>
            <div className={styles.productTileBox}>
                {
                    data.map((f, idx) =>
                        <div className={`${styles.tile}`} key={idx} onClick={() => pushRoute(`${baseUrl}${f.url}`)}>
                            <figure><Image width={215} height={215} className="img-responsive" src={`/assets/images/${f.image}`} alt={f.name} /></figure>
                            <NavLink href={`${baseUrl}${f.url}`}><h3>{f.name}</h3></NavLink>
                        </div>
                    )
                }
                <div className={`${styles.tile} ${styles.titleTile}`}>
                    <figure>
                        {!isMobileView && <NavLink href={`${baseUrl}`}><p className="font16 lineHeight24 textfff">Explore all <br />
                            Categories</p>
                        </NavLink>
                        }
                    </figure>
                </div>
            </div>
            {isMobileView && <div className={styles.mobBtn}>
                <NavLink href={`${baseUrl}`}><button className="btn btnBgBlue">Explore All Categories</button></NavLink>
            </div>
            }
        </div>
    </section>)
}