import { CountryCode } from '../../lib/constants';
import { useGlobalContext } from '../../lib/context';
import dynamic from 'next/dynamic';
const IndiaBannerWeb=dynamic(()=>import('./IndiaBannerWeb'));
const IndiaBannerMob=dynamic(()=>import('./IndiaBannerMob'));

export function HomeBanner(props: { countryCode: CountryCode }) {
	const { props:{isMobileView} } = useGlobalContext();

	const homeBanner=()=>{
		if(isMobileView){
				return <IndiaBannerMob />;
		}else{
				return <IndiaBannerWeb />;
		}
	}

	return (
		<div style={{height:'640px'}}>
			{homeBanner()}
		</div>
	)
}