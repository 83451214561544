import blogService from '@/lib/services/blogService';
import productService from '@/lib/services/productService';
import {IHomeData, IMetaHead, IPageMenu, ITestimonial} from '@/interfaces';
import {HomeBanner} from '@/components/banner/HomeBanner';
import {HowItWorks, WhatSetsUsApart, WhyClientsChooseUS} from '@/components/home';
import {CountryCode} from '@/lib/constants';
import {MetaHead} from '@/components/shared/metaHead';
import {SchemaHead} from '@/components/shared/schemaHead';
import { IReviews } from '@/interfaces/IReviews';
import reviewService from '@/lib/services/reviewService';
import MediaCoverage from '@/components/home/MediaCoverage';
import dynamic from 'next/dynamic';
import { MarketPlaceTopProducts } from '@/components/home/MarketPlaceTopProducts';
const OurExecutedProjects=dynamic(()=>import('@/components/project').then(mod=>mod.OurExecutedProjects));
const TrendingCategory=dynamic(()=>import('@/components/productCategory/TrendingCategory').then(mod=>mod.TrendingCategory));
const OtherDetails=dynamic(()=>import('@/components/services/OtherDetails').then(mod=>mod.OtherDetails));
const OurService=dynamic(()=>import('@/components/services/OurServices').then(mod=>mod.OurService));
const GoogleReviews=dynamic(()=>import('@/components/shared/googleReviews').then(mod=>mod.GoogleReviews),
{
    ssr:false
});
const FeaturedBlogS=dynamic(()=>import('@/components/blog/FeaturedBlogS').then(mod=>mod.FeaturedBlogS));
const BlogCategories=dynamic(()=>import('@/components/blog/BlogCategories').then(mod=>mod.BlogCategories));
const HomeInterior=dynamic(()=>import('@/components/services/HomeInterior').then(mod=>mod.HomeInterior),{
    ssr:false
});
const CitiLeadForm=dynamic(()=>import('@/components/shared/lead/CitiLeadForm').then(mod=>mod.CitiLeadForm),
{
    ssr:false
});
const InHomeLongBody=dynamic(()=>import('@/components/longBody/homeLongBody/InHomeLongBody').then(mod=>mod.InHomeLongBody));
const OurBrandPartners=dynamic(()=>import('@/components/home/OurBrandPartners').then(mod=>mod.OurBrandPartners),{
    ssr:false
});
const WhyChooseUS=dynamic(()=>import('@/components/home/WhyChooseUS').then(mod=>mod.WhyChooseUS),
{
    ssr:false
});
type Props = {
    data: IHomeData
    reviewsdata:IReviews[]
    navSchemaData:IPageMenu[]
    testimoninals:ITestimonial[]
  }
const Home = ({ data ,reviewsdata,navSchemaData,testimoninals}:Props) => {
    const siteURL=process.env.SITE_URL;
    const metaData:IMetaHead={
        title:"Interior Company: Best Interior Designers Company in India",
        description : "Interior company provides the best interior design services for your kitchen, bedroom, living room & more across India. Explore our budget-friendly solutions and design your home from our best interior designers and decorators.",
        videoURL:"https://www.youtube.com/embed/MtqW2tjVWrk",
        // hreflangInCountry:"INR,AED"
    }

    const schemaData =[
        {
            __html: `
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Interior Company",
                "description": "Interior Company by Square Yards Brings Affordable and Modern Interior Designing Solutions: Get Expert Advice for Living Rooms, Bathroom, Kitchen and Full Home Decor Services.",
                "url": "${siteURL}",
                "logo": "${siteURL}/interior-logo.svg"
            }`
          },
          {
            __html: `
            {
                "@context": "http://schema.org/",
                "@type": "LocalBusiness",
                "name": "Interior Company",
                "image": "${siteURL}/interior-logo.svg",
                "telephone": "+91 8000491807",
                "url": "${siteURL}",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "Good Earth Business Bay, 9th Floor, Sector 58 Gurgaon",
                    "addressLocality": "Gurgaon",
                    "addressRegion": "Haryana",
                    "postalCode": "122011",
                    "addressCountry": "India"
                }                
            }`
          },
          {
            __html:`
             {
                "@context": "https://schema.org",
                "@type": "ItemList",
                "itemListElement": [
                    {
                        "@type": "ListItem",
                        "position": 1,
                        "item": {
                            "@type": "VideoObject",
                            "name": "The Happy Home Story by Ridhima Sharma | Interior Company | Square Yards | Gurgaon",
                              "description": "Ridhima and her husband were looking for a cosy and welcoming place amidst the hustle-bustle of the city. They contacted Interior Company, briefed the designers about their requirements, and explained their idea of a 'dream home'.",
                              "thumbnailUrl": "https://i.ytimg.com/vi/0nuy8Fkae_k/default.jpg",
                              "uploadDate": "2021-12-30T10:50:59Z",
                              "duration": "PT6M7S",
                              "embedUrl": "https://www.youtube.com/embed/0nuy8Fkae_k",
                              "interactionCount": "4694",
                            "url": "${siteURL}"
                        }
                    },
                    {
                        "@type": "ListItem",
                        "position": 2,
                        "item": {
                            "@type": "VideoObject",
                            "name": "The Happy Home Story by Sangeeta Chaudhary | Interior Company | Square Yards | Banglore |",
                              "description": "In this episode of 'Happy Home Story', you'll explore the interiors of Sangeeta Chaudhary's house in Bangalore. She is another happy client of Interior Company who trusted the design experts with several spaces of her home.",
                              "thumbnailUrl": "https://i.ytimg.com/vi/xoNMc_4Hh_I/default.jpg",
                              "uploadDate": "2021-12-23T09:20:49Z",
                              "duration": "PT1M18S",
                              "embedUrl": "https://www.youtube.com/embed/xoNMc_4Hh_I",
                              "interactionCount": "711",
                            "url": "${siteURL}"
                        }
                    },
                    {
                        "@type": "ListItem",
                        "position": 3,
                        "item": {
                            "@type": "VideoObject",
                            "name": "The Happy Home Story by Geeta Harshwardhan | Interior Company | Square Yards | Bengaluru",
                              "description": "In this video, you'll witness Interior Company's interior experts designing the spacious spaces of Geeta Harshwardhan's house. She had a vision ready for her dream home and wanted our designers to give creative touches to her visions.",
                              "thumbnailUrl": "https://i.ytimg.com/vi/Ik_NsjjMutE/default.jpg",
                              "uploadDate": "2021-12-23T11:11:31Z",
                              "duration": "PT1M43S",
                              "embedUrl": "https://www.youtube.com/embed/Ik_NsjjMutE",
                              "interactionCount": "658",
                            "url": "${siteURL}"
                        }
                    },
                    {
                        "@type": "ListItem",
                        "position": 4,
                        "item": {
                            "@type": "VideoObject",
                            "name": "The Happy Home Story by Muralidhar Kulakarni | Interior Company | Square Yards | Bengaluru",
                              "description": "This heavenly house of Mr Muralidhar Kulakarni was carefully designed by our experienced designers in Bengaluru. They were asked to give the home a polished, elegant look under a fixed budget. Our team sat with the owners and planned an extensive revamp.",
                              "thumbnailUrl": "https://i.ytimg.com/vi/WZpA4xWq3AE/default.jpg",
                              "uploadDate": "2021-12-30T10:14:49Z",
                              "duration": "PT54S",
                              "embedUrl": "https://www.youtube.com/embed/WZpA4xWq3AE",
                              "interactionCount": "1710",
                            "url": "${siteURL}"
                        }
                    },
                    {
                        "@type": "ListItem",
                        "position": 5,
                        "item": {
                            "@type": "VideoObject",
                            "name": "| Interior Company | The Happy Home Story by Mr. Ankit Mittal | Square Yards | Gurugram |",
                              "description": "Introducing the elegance of luxury home interiors: A tour of our client's stunning residence. Step into the world of opulence and sophistication as we take you on a virtual tour of our client's breathtaking home in Gurgaon.",
                              "thumbnailUrl": "https://i.ytimg.com/vi/N0itDIMxRkg/default.jpg",
                              "uploadDate": "2023-03-20T07:18:21Z",
                              "duration": "PT12M36S",
                              "embedUrl": "https://www.youtube.com/embed/N0itDIMxRkg",
                              "interactionCount": "229",
                              "url": "${siteURL}"
                        }
                    }    
                ]
            }`
          },
          {
            __html:`
            {
                "@context":"https://schema.org",
                "@type":"FAQPage",
                "mainEntity":[{"@type":"Question",
                "name":"What does an interior designer do?",
                "acceptedAnswer":{"@type":"Answer",
                "text":"An interior designer at Interior Company coordinates with clients to understand their preferences and draws initial design concepts and space planning proposals while offering a virtual experience to transform their dream home into a reality."}},
                {
                "@type":"Question",
                "name":"What are the 5 steps to the interior design process?",
                "acceptedAnswer":{"@type":"Answer",
                "text":"The five phases of Home Interior Design include:
                Strategic Planning.
                Conceptual Development.
                Design Development.
                Construction Implementation.
                Construction Maintenance & Project Management
                "}},
                {
                "@type":"Question",
                "name":"What is a luxury in interior design?",
                "acceptedAnswer":{
                "@type":"Answer",
                "text":"Luxury is synonymous with comfort, elegance, and lavishness. It notions individuality and can appear in any design style, from modern to traditional, curating custom spaces and plush furnishings that define a unique visual experience."}},
                {
                "@type":"Question",
                "name":"How can I get free interior design advice?",
                "acceptedAnswer":{
                "@type":"Answer",
                "text":"Click on Interior Company’s website and book a free consultation with our experts. Our team of interior designers take into account your desires and budgetary constraints, planning spaces that resonate with your style."}},
                {
                "@type":"Question",
                "name":"What is the difference between an interior designer and an interior decorator?",
                "acceptedAnswer":{
                "@type":"Answer",
                "text":"An interior designer specialises in comprehensive space planning, architectural concepts, furniture design and material selection, whereas an interior decorator focuses on the style, aesthetics and surface appearance of the living space."}
                }]
            }`
          }
    ]

    return(
        <>
            <MetaHead metaData={metaData} />
            <SchemaHead data={schemaData} navSchemaData={navSchemaData} />
            <HomeBanner countryCode={CountryCode.INR} />
            <OtherDetails/>
            <OurService countryCode={CountryCode.INR} />
            <BlogCategories countryCode={CountryCode.INR}/>
            <TrendingCategory countryCode={CountryCode.INR} products={data.Products||[]} />
            <MarketPlaceTopProducts/>
            <OurExecutedProjects countryCode={CountryCode.INR} />
            <WhyChooseUS/>
            <HowItWorks/>
            <WhatSetsUsApart/>            
            <WhyClientsChooseUS testimoninals={testimoninals}/>
            <GoogleReviews reviewsdata={reviewsdata}/>
            <MediaCoverage />
            <FeaturedBlogS countryCode={CountryCode.INR} latestPosts={data.BlogLatestPosts} h2Text={"Featured Blogs"} pText={"Your personal aesthetic coupled with our visual inspiration is a mood board made in heaven!"}/>
            <OurBrandPartners countryCode={CountryCode.INR}/>
            <HomeInterior/>
            <CitiLeadForm/>
            <InHomeLongBody/>
        </>
    )
}

export const getServerSideProps = async (context:any) => {
    const latestPosts=await blogService.featuredPostsByCountry(CountryCode.INR);
    const products=await productService.getProductByCategory(CountryCode.INR);
    const reviewsdata = await reviewService.getGoogleReview(CountryCode.INR);
    const testimoninals = await blogService.GetHappyClientCity();
    const data:IHomeData={
        BlogLatestPosts:latestPosts,
        Products:products
    };
    return {
        props: {data,reviewsdata,testimoninals}, 
      }    
}

export default Home;